import React from "react"
import useTranslations from "../../../utils/useTranslations"
import ComparisonPanels from "../../../components/comparisonPanels"
import LocalizedLink from "../../../components/localizedLink"
import Logo from "../../../components/logo"
import backgrounds from "../../../components/backgrounds"


const BittorrentWebCompare = ({ pageContext: {text, pageName, locale} }) => {
const t = useTranslations(text)

const logoTitle = <>
	<div className="font-weight-normal text-nowrap">Compare <div className="compare-page-logo-container"><Logo color="black" tag="p" productName="Web" fsCoefficient="15.5" className="compare-page-logo"/></div> Versions</div>
</>
const titleContent = (locale === "en") ? logoTitle : t("Compare BitTorrent Web Versions")


  return (
  	<div className="container-fluid py-5 mt-5 background-noise-dark" style={backgrounds.darkCorners}>
	    <div className={"container px-0"}>
	      <h1 className="h2 text-center text-dark">{titleContent}</h1>
	      <ComparisonPanels pageContext={{ "text":text, "pageName":pageName }}>
		      <p className="text-dark text-center mt-5">
		      	<LocalizedLink to="/products/win/bittorrent-web-premium" id="products-win-btweb-compare-premium-learnmore" className="font-weight-bold">
		      		{t(`Learn more`)}
		      	</LocalizedLink> {t(`about BitTorrent Web premium products`)}
		      </p>
		  </ComparisonPanels>
	    </div>
  	</div>
  )
}

export default BittorrentWebCompare